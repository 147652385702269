import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | number | string

export enum ChainId {
  // ETHEREUM = 1,
  SEPOLIA = 11155111,
  // BSC = 56,
  BSC_TESTNET = 97
  // POLYGON = 137,
  // AMOY = 80002,
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const FACTORY_ADDRESS = '0xc28aCF5bd6531C97d8aB919dD167a56584Cef6a8'

export const FACTORY_ADDRESS_MAP = {
  // [ChainId.ETHEREUM]: FACTORY_ADDRESS,
  [ChainId.SEPOLIA]: '0xc28aCF5bd6531C97d8aB919dD167a56584Cef6a8',
  // [ChainId.BSC]: FACTORY_ADDRESS,
  [ChainId.BSC_TESTNET]: '0x789FEc2EC24ed69049f7b8e5dDC3ce3e5ac7F157'
  // [ChainId.POLYGON]: FACTORY_ADDRESS,
  // [ChainId.AMOY]: '0xd6D9A92827979052f2c13204985Cb12C371D2e6A'
}

export const INIT_CODE_HASH = '0xfcd2bd9671ccc321eb0c0076d57595a276a9a3a69f6db76d8f334549e7ee3b1f'

export const INIT_CODE_HASH_MAP = {
  // [ChainId.ETHEREUM]: INIT_CODE_HASH,
  [ChainId.SEPOLIA]: INIT_CODE_HASH,
  // [ChainId.BSC]: INIT_CODE_HASH,
  [ChainId.BSC_TESTNET]: INIT_CODE_HASH
  // [ChainId.POLYGON]: INIT_CODE_HASH,
  // [ChainId.AMOY]: '0xfcd2bd9671ccc321eb0c0076d57595a276a9a3a69f6db76d8f334549e7ee3b1f'
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const FEES_NUMERATOR = JSBI.BigInt(9975)
export const FEES_DENOMINATOR = JSBI.BigInt(10000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
